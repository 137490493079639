import request from '@/utils/request';

// 支付宝支付-需要短信验证码
export const codeDlipayPay = ( data) => request({
  url: '/huanxinxiang-service/v1/alipay-sms-pay/',
  method: 'post',
  headers: {
    "App-Name": "huanxinxiang-hailuo",
  },
  data,
});

//支付宝支付无需验证码
export const alipayPay = ( data) => request({
  url: '/huanxinxiang-service/v1/alipay-pay/',
  method: 'post',
  headers: {
    "App-Name": "huanxinxiang-hailuo",
  },
  data,
});

// 获取短信
export const getCode = (data) => request({
  url: '/huanxinxiang-service/v1/code/',
  method: 'post',
  data,
});

// 上报签约
export const alipayCallbackPay = (data) => request({
  url: '/huanxinxiang-service/v1/alipay-callback-pay/',
  method: 'post',
  data,
});
